import { action, observable } from 'mobx';

export default class ConfirmPayModel {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public visible = false;

  @observable public parent = null;

  @observable public passwordExist = true; // 支付密码是否存在

  @action
  public onOpenModal = (): void => {
    this.visible = true;
  };

  @action
  public onCloseModal = (): void => {
    this.visible = false;
  };
 
  @action
  public openPwdModal = (): void => {
    this.parent.passwordModel && this.parent.passwordModel.onOpenModal(true);
  };
}

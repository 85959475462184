import { observer } from 'mobx-react';
import React, { useMemo, useEffect } from 'react';
import cashierTips from '../../assets/images/cashierTips.png';
import { PUBLIC_IMAGE_URL } from '../../utils';
import styles from './index.less';
import CashierStore from './store';
import CashierContent from './widget/cashierContent';
import ConfirmPayModal from './widget/confirmPayModal/index';
import PasswordModal from './widget/passwordModal/index';
import PayResult from './widget/payResult';
import { ProtalModal } from './widget/protalModal';
import WePay from './widget/wePay';
import { Modal } from 'antd';

const Cashier = observer(() => {
  const store = useMemo(() => {
    return new CashierStore();
  }, []);

  useEffect(() => {
    store.getPayCashier();
  }, []);

  const { process, confirmPayModel, passwordModel, protalStore, paymentPasswordOpen, paymentPasswordLoading, handlePaymentPasswordOk, handlePaymentPasswordCancel, h5Url } = store;
  return (
    <div className={styles.cashier}>
      {process === 0 && <CashierContent store={store}/>}
      {process === 1 && <WePay store={store}/>}
      {process === 2 && <PayResult store={store}/>}
      <ConfirmPayModal
        cashierStore={store}
        store={confirmPayModel}
      />
      <PasswordModal store={passwordModel}/>
      <ProtalModal store={protalStore}/>
      {/* <div className={styles.cashierTips}>
        <img
          className={styles.cashierTipsImg}
          src={SZBankLogo}
        />
      </div> */}
      <div className={styles.footer}>
        <img
          alt=""
          className={styles.footerImg}
          src={`${PUBLIC_IMAGE_URL}SZBankLogo.png`}
        />
      </div>
      <Modal
        confirmLoading={paymentPasswordLoading}
        footer={null}
        onCancel={() => {
          handlePaymentPasswordCancel('取消支付！');
        }}
        onOk={handlePaymentPasswordOk}
        open={paymentPasswordOpen}
        title="支付密码"
        width={620}
      >
        <div style={{
          width: 580,
          height: 410,
          margin: 'auto',
        }}
        >
          <iframe
            frameBorder={0}
            src={h5Url}
            style={{
              width: 580,
              height: 410,
            }}
          />
        </div>
      </Modal>
    </div>
  );
});

export default Cashier;

import { observer } from 'mobx-react';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import scanIcon from '../../../assets/images/scanIcon.png';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from '../index.less';
import type CashierStore from '../store';

interface Props {
  store: CashierStore;
}

const WePay = observer((props: Props) => {
  const { wxPayUrl, changeProcess, getPayInfo, payWay } = props.store;

  const [
    codeImg,
    setCodeImg,
  ] = useState('');

  const [
    loading,
    setLoading,
  ] = useState(true);

  const [
    countdown,
    setCountdown,
  ] = useState((payWay.code === 'wechat-pc-pay-payway' || payWay.code === 'szbank-wechat-scan-payway') ? 60 : 120); // 微信支付60s过期；数字人民币120s过期
  const [
    timeoutVisible,
    setTimeoutVisible,
  ] = useState(false);

  useEffect(() => {
    if (wxPayUrl) {
      generateQR();
    }
  }, [wxPayUrl]);

  useEffect(() => {
    return () => {
      props.store.wxPayUrl = '';
    };
  }, []);

  useEffect(() => {
    setCodeImg('');
    setLoading(true);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const generateQR = async() => {
    try {
      const image = await QRCode.toDataURL(wxPayUrl);
      setCodeImg(image);
      setLoading(false);
    } catch (err) {
      setCodeImg('');
      console.error(err);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        clearInterval(timer);
        setTimeoutVisible(true);
      } else {
        setCountdown(countdown - 1);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const refreshCodeImg = () => {
    setLoading(true);
    getPayInfo();
    setTimeoutVisible(false);
    setCountdown(60);
  };

  return (
    <div className={styles.wePay}>
      <div className={styles.header}>
        <div
          className={styles.back}
          onClick={() => changeProcess(0)}
        >
          &lt;&nbsp;返回
        </div>
        <div className={styles.label}>
          付款金额：
          <span className={styles.amount}>
            &yen;
            {payWay?.totalAmount?.toFixed(2)}
          </span>
          <span className={styles.mr8}>
            元
          </span>
          {payWay?.charge && (
            <>
              <span className={styles.poundage}>
                (含手续费
              </span>
              <span className={styles.poundageAmount}>
                &yen;
                {payWay?.chargeFee?.toFixed(2)}
              </span>
              <span className={styles.poundage}>
                元)
              </span>
            </>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.wePayWrapper}>
          <img
            className={styles.weChatIcon}
            src={`${PUBLIC_IMAGE_URL}${payWay?.logo}.png`}
          />
          <span>
            {(payWay.code === 'wechat-pc-pay-payway' || payWay.code === 'szbank-wechat-scan-payway') ? '微信支付' : '数字人民币支付'}
          </span>
        </div>
        <div className={styles.tips}>
          二维码还有
          <span className={styles.strongText}>
            {countdown}
          </span>
          秒过期，过期后请点击重新获取刷新二维码
        </div>
        <div className={styles.picWrapper}>
          <div className={styles.left}>
            {timeoutVisible && (
              <div
                className={styles.mask}
                onClick={refreshCodeImg}
              >
                <div className={styles.timeoutTips}>
                  二维码已过期，请点击重新获取
                </div>
              </div>
            )}
            <Spin spinning={loading}>
              <img
                className={styles.codeImg}
                src={codeImg}
              />
            </Spin>
            
            <div className={styles.scanTips}>
              <img
                className={styles.scanIcon}
                src={scanIcon}
              />
              <span>
                请使用
                {(payWay.code === 'wechat-pc-pay-payway' || payWay.code === 'szbank-wechat-scan-payway') ? '微信' : '数字人民币APP'}
                扫一扫
                <br/>
                扫描二维码支付
              </span>
            </div>
          </div>
          <img
            className={styles.wePayPic}
            src={`${PUBLIC_IMAGE_URL}${payWay?.logo}Pic.png`}
          />
        </div>
      </div>
    </div>
  );
});

export default WePay;

import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import tipsIcon from '../../../../assets/images/tipsIcon.png';
import type CashierStore from '../../store';
import styles from './index.less';
import type ConfirmPayModel from './model';

@observer
export default class ConfirmPayModal extends React.Component<{ store?: ConfirmPayModel; cashierStore?: CashierStore; }> {
  render(): JSX.Element {
    const {
      visible,
      openPwdModal,
      onCloseModal,
    } = this.props.store;
    const {
      payWay,
      orderInfo,
    } = this.props.cashierStore;
    return (
      <Modal
        centered
        footer={null}
        maskClosable={false}
        onCancel={() => onCloseModal()}
        open={visible}
        title="支付金额确认"
        width={392}
      >
        <div className={styles.confirmPayModal}>
          <div className={styles.title}>
            支付金额
          </div>
          <div className={styles.totalAmount}>
            &yen;
            {payWay?.totalAmount?.toFixed(2)}
          </div>
          <div className={styles.textAlignCenter}>
            <div>
              <div className={styles.detail}>
                <span className={styles.label}>
                  商品金额：
                </span>
                <span className={styles.amount}>
                  ￥
                  {orderInfo.amount?.toFixed(2)}
                </span>
                元
              </div>
              {payWay?.charge && (
                <div className={styles.detail}>
                  <span className={styles.label}>
                    手续费：
                  </span>
                  <span className={styles.amount}>
                    ￥
                    {payWay?.chargeFee?.toFixed(2)}
                  </span>
                  元
                </div>
              )}
            </div>
          </div>
          {payWay?.charge && (
            <div className={styles.textAlignCenter}>
              <div className={styles.tips}>
                <img
                  className={styles.tipsIcon}
                  src={tipsIcon}
                />
                {payWay?.feeRemark}
              </div>
            </div>
          )}
          <div className={styles.textAlignCenter}>
            <Button
              className={styles.okBtn}
              onClick={openPwdModal}
              type="primary"
            >
              确定并支付
            </Button>
          </div>

        </div>
      </Modal>
    );
  }
}

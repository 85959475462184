import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import CryptoJS from 'crypto-js';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable, computed } from 'mobx';
import React from 'react';
import { api } from '../../../../utils';
import PasswordInputModel from '../passwordInput/model';

export default class PasswordModel {
  constructor(parent) {
    this.parent = parent;
    this.passwordInputModel = new PasswordInputModel(this);
  }

  @observable public visible = false;

  // true为已有支付密码 false为无支付密码
  @observable public passwordFormType = false;

  @observable public passwordFormRef = React.createRef<FormInstance>();

  @observable public passwordKey = '';

  @observable public parent = null;

  @observable public timer = null;

  @observable public passwordInputModel: PasswordInputModel;

  @observable public loading = false;

  /**
   * 设置密码/验证密码
   */
  @action
  public encryptKey = async(): Promise<void> => {
    // 获取加密key
    this.loading = true;
    try {
      const passwordKeyReq = await request<BaseData<string>>({ url: api.getEncryptKey });
      this.passwordKey = passwordKeyReq?.data;
    } catch {
      this.loading = false;
    }
    if (this.passwordFormType) {
      try {
        const encodedPassword = this.handleEncryption(0);

        // 不再校验支付密码
        // await request<BaseData>({
        //   url: api.checkPassword,
        //   method: 'POST',
        //   data: { encodedPassword },
        // });
        // this.loading = false;
        this.onCloseModal();
        this.parent && this.parent.pwdInputCallback(encodedPassword);
      } catch (error) {
        this.loading = false;
        message.destroy();
        this.passwordInputModel.inputStatus[0].errorTip = error.data.info;
        this.passwordInputModel.inputStatus[0].password = [];
      } finally {
        this.loading = false;
      }
    } else {
      // 设置支付密码
      try {
        const setPasswordReq = await request<BaseData>({
          url: '/api/pay/web/password/setPassword',
          method: 'POST',
          data: {
            encodedPassword: this.handleEncryption(0),
            secondEncodedPassword: this.handleEncryption(1),
          },
        });
        this.loading = false;
        message.success(setPasswordReq?.data);
        this.passwordFormType = true;
        this.passwordInputModel.changeInput(6, false);
        this.visible = false;
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    }
  };

  // 加密方法
  @action
  public handleEncryption = (number: number): string => {
    const pass = number === 0 ? this.passwordInputModel.inputStatus[0].password.join('') : this.passwordInputModel.inputStatus[1].password.join('');
    const key = CryptoJS.enc.Utf8.parse(this.passwordKey);
    const srcs = CryptoJS.enc.Utf8.parse(pass);
    const encrypted = CryptoJS.DES.encrypt(srcs, key, {
      iv: '',
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  };

  @action
  public onOpenModal = (type: boolean): void => {
    this.visible = true;
    this.passwordInputModel.changeInput(6, !type);
    this.passwordFormType = type;
  };

  @action
  public onOkModal = (): void => {
    if (!this.passwordInputModel.inputStatus[0].includeOther && this.passwordInputModel.inputStatus[0].password.filter((item) => (item !== undefined)).length === 6) {
      if (!this.passwordFormType && this.passwordInputModel.inputStatus[0].password.join('') !== this.passwordInputModel.inputStatus[1].password.join('')) {
        return;
      }
      this.encryptKey();
    }
  };

  @action
  public onCloseModal = (): void => {
    this.visible = false;
    this.passwordInputModel.inputStatus = [];
    this.passwordFormRef?.current?.resetFields();
  };
}

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Form, Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type PasswordInputModel from './model';

@observer
export default class PasswordInput extends React.Component<{ store?: PasswordInputModel ; }> {
  render() {
    const { store } = this.props;
    return (
      store.inputStatus.map((rowItem) => (
        <Form.Item
          className={styles.inputForm}
          key={rowItem.label}
          label={rowItem.label}
          style={{ width: rowItem.label ? 300 : 232 }}
        >
          {
            store.inputNumber.map((inputItem, index) => (
              <Input
                autoComplete="new-password"
                key={inputItem}
                maxLength={1}
                onChange={(e) => store.inputPassword(index, e, rowItem.label)}
                type={rowItem.type}
                value={rowItem.password[index]}
              />
            ))
          }
          <p className={styles.errorTip}>
            {rowItem.errorTip}
          </p>
        </Form.Item>
      ))
    );
  }
}

import { action, observable } from 'mobx';

export default class PasswordInputModel {
  constructor(parent) {
    console.log(parent);
  }

  @observable public inputNumber = [];

  @observable public inputStatus = [];

  /**
   * 根据是否存在第二排判断是设置密码还是验证密码
   * @param number 输入框个数
   * @param isSet 是否为设置密码
   */
  @action
  public changeInput = (number, isSet?: boolean) => {
    this.inputNumber = Array.from(new Array(number).keys());
    this.inputStatus = isSet ? [
      {
        first: true,
        includeOther: false,
        password: [],
        label: '支付密码',
        errorTip: '',
        type: 'password',
      },
      {
        first: true,
        includeOther: false,
        password: [],
        label: '确认支付密码',
        errorTip: '',
        type: 'password',
      },
    ] : [
      {
        first: true,
        includeOther: false,
        password: [],
        label: '',
        errorTip: '',
        type: 'password',
      },
    ];
  };

  /**
   * 输入事件
   * @param number 当前输入框得Index
   * @param e 前端输入框 e
   * @param currentLabel 当前行Label
   */
  @action
  public inputPassword = (number, e, currentLabel) => {
    const index = currentLabel === '确认支付密码' ? 1 : 0;

    // 如果执行的删除操作 对应的输入框值变为undefined
    this.inputStatus[index].password[number] = e.target.value !== '' ? Number(e.target.value) : undefined;
    this.inputStatus[index].first = false;

    // 判断是否存在数字
    this.inputStatus[index].includeOther = !new RegExp(/^[0-9]+$/).test(this.inputStatus[index].password.join(''));
    
    // 当输入框有值时，自动跳到下一个输入框，当整行跳完时。聚焦最后一个输入框/删除时，自动跳到前一个输入框，整行删完聚焦当前行第一个输入框
    if (e.target.value !== '') {
      number === 5 ? e.target.parentNode.children[number].focus() : e.target.parentNode.children[number + 1].focus();
    } else {
      if (number - 1 < 0) {
        return;
      }
      e.target.parentNode.children[number - 1].focus();
    }

    this.fliterErrorTip(currentLabel);
  };

  /**
   * 获取错误提示
   * @param currentLabel 当前行label
   */
  @action
  public fliterErrorTip = (currentLabel) => {
    if (currentLabel === '确认支付密码') {
      this.inputStatus[1].errorTip = !this.inputStatus[1].first && this.inputStatus[1].password.join('') !== this.inputStatus[0].password.join('') ? '两次输入密码不同' : '';
    } else {
      this.inputStatus[0].errorTip = !this.inputStatus[0].first && (this.inputStatus[0].includeOther ? '请输入6位数字' : (this.inputStatus[0].password.filter((item) => (item !== undefined)).length !== 6 ? '请输入密码' : ''));
    }
  };

  @action
  public changeInputType = (currentLabel, type) => {
    currentLabel === '确认支付密码' ? (this.inputStatus[1].type = type === 'text' ? 'password' : 'text') : (this.inputStatus[0].type = type === 'text' ? 'password' : 'text');
  };
}

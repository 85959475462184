import { Button, Checkbox, Col, Modal, Radio, Row, Space, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type Store from './store';

export const ProtalModal = observer((props: { store: Store; }) => {
  const { loading, visible, protalHtml, title, closeModal } = props.store;
  return (
    <Modal
      centered
      closable={false}
      destroyOnClose
      footer={(
        <Button
          className={styles.confirmBtn}
          onClick={closeModal}
          type="primary"
        >
          确认
        </Button>
      )}
      maskClosable={false}
      title={title}
      visible={visible}
      width={600}
      
    >
      <Spin spinning={loading}>
        <div
          className={styles.protalWrap}
          dangerouslySetInnerHTML={{ __html: protalHtml }}
        />
      </Spin>
    </Modal>
  );
});

import { message } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { debounce } from 'lodash';
import { action, observable, toJS } from 'mobx';
import { api } from '../../utils';
import type { CashierInfo, IousInfo, OrderInfo, PayResult, PayWay, WePayResponse } from './interface';
import ConfirmPayModel from './widget/confirmPayModal/model';
import PasswordModel from './widget/passwordModal/model';
import ProtalStore from './widget/protalModal/store';

export default class CashierStore {
  constructor() {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const tradeOrderIds = search.get('tradeOrderIds');
    const outBatchNo = search.get('outBatchNo');
    const cashierCode = search.get('cashierCode');
    const noRealName = search.get('noRealName');
    const totalOrder = search.get('orderNum');// 交易单数量
    const notifyUrl = search.get('callBackUrl');
    this.cashierCode = cashierCode || 'xuankuan-pc-cashier';
    console.log(tradeOrderIds, outBatchNo, noRealName, totalOrder, notifyUrl);

    // 按照批次付款
    if (outBatchNo) {
      this.batch = true;
      this.outBatchNo = outBatchNo;
      this.totalOrder = Number(totalOrder);
      this.notifyUrl = notifyUrl;
    }
    if (tradeOrderIds) {
      this.tradeOrderIdList = JSON.parse(tradeOrderIds);
    }
    if (noRealName) {
      this.noRealName = Boolean(Number(noRealName));
    }
  }

  public outBatchNo = '';// 批次号

  public batch = false;// 是否按批次号支付

  public totalOrder = 0;// 交易单数量

  public notifyUrl = '';// 回调地址

  @observable public process = 0; // 0： 支付列表； 1：扫码支付 2：结果页

  @observable public loading = false;

  @observable public h5Url: string;

  @observable public paymentPasswordOpen = false;

  @observable public paymentPasswordLoading = false;

  @observable public noRealName = false; // 是否不校验实名认证

  @observable public tradeOrderIdList = []; // 业务批次号

  @observable public cashierCode = ''; // 收银台支付code

  @observable public orderInfo: OrderInfo = {
    amount: 0, // 总额
    mergeName: '', // 名称
    mergeNo: 0, // 订单号
  };

  @observable public payWayList: PayWay[] = []; // 支付方式list

  @observable public platformRealnameApply = 2; // 实名认证。0:未实名认证 1:实名申请中 2:已实名 3:实名失败

  @observable public errorMsg = ''; // 密码错误提示

  @observable public payWay: PayWay; // 支付方式

  @observable public wxPayUrl = '';

  @observable public payResult = false; // 支付是否成功

  @observable public failReason = ''; // 失败原因

  @observable public confirmPayModel = new ConfirmPayModel(this);

  @observable public passwordModel = new PasswordModel(this);

  @observable public protalStore = new ProtalStore(this);

  @observable public agreeIousProtal = false;

  @observable public splitDate = '';

  @observable public iousInfo: IousInfo = null;

  @observable public loanTermNum = 1;

  @observable public payChannelOrderNo = ''; // 渠道订单号

  @observable public timer = null; // 定时器

  @observable public dateTime = 0; // 获取支付二维码的时刻

  @action public onChangeNumber = (loanTermNum): void => {
    this.loanTermNum = loanTermNum;
  };

  @observable public changeProcess = (process: number): void => {
    this.process = process;
  };

  // 获取收银台信息(根据批次号或交易单id)
  @action public getPayCashier = (): void => {
    const data = { code: this.cashierCode };
    if (this.batch) {
      Object.assign(data, { outBatchNo: this.outBatchNo });
    } else {
      Object.assign(data, { tradeOrderIdList: this.tradeOrderIdList });
    }
    this.loading = true;
    request({
      url: api.getPayCashier,
      method: 'POST',
      data,
    }).then((res: BaseData<CashierInfo>) => {
      this.orderInfo = res.data.orderInfo;
      this.platformRealnameApply = this.noRealName ? 2 : res.data.platformRealnameApply;

      const allPayWayList = res.data.payWayList || [];
      this.payWayList = allPayWayList;

      /**
       * 过滤不可用的支付方式
       */
      const availablePayWayList = allPayWayList.filter((e) => {
        if (this.platformRealnameApply !== 2) {
          return false;
        }

        if (!e.available) {
          return false;
        }
        if (e.code === 'wechat-pc-pay-payway' || e.code === 'digital-yuan-pc-pay-payway') {
          return true;
        }

        return e.availableBalance >= this.orderInfo.amount;
      });

      /**
       * 设置默认支付方式
       */
      const firstAvailablePayWay = availablePayWayList.length > 0 ? availablePayWayList[0] : undefined;
      if (!firstAvailablePayWay) {
        return;
      }
      this.onChangePayType(firstAvailablePayWay);
    })
      .catch((res) => {
        if (res.code === 101) {
          message.error(res.data || res.info);
          setTimeout(() => {
            top.egenie.closeTab('cashier');
          }, 1000);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  };

  // 查询白条分期信息
  @action public queryPcCashierFinanceVo = async(amount: number): Promise<void> => {
    const data = {
      amount,
      productCode: 'YLbaitiao',
      thirdPartyFundsId: toJS(this.payWay)?.thirdPartyFundsId,
      code: this.payWay.code,
    };
    this.batch ? Object.assign(data, { outBatchNo: this.outBatchNo }) : Object.assign(data, { tradeOrderIdList: this.tradeOrderIdList });
    const res: BaseData<IousInfo> = await request({
      url: api.queryPcCashierFinanceVo,
      method: 'post',
      data,
    });
    this.iousInfo = res.data;
  };

  @action public onChangePayType = debounce((payWay: PayWay): void => {
    if (payWay?.code === 'wechat-pc-pay-payway' || payWay?.code === 'digital-yuan-pc-pay-payway' || payWay?.code === 'szbank-wechat-scan-payway') {
      this.payWay = payWay;
    }
    const { code, availableBalance, available } = payWay;
    const disabled = availableBalance < this.orderInfo.amount || this.platformRealnameApply !== 2 || ((code === 'finance-pc-pay-payway' || code === 'finance-other-pc-pay-payway' || code === 'wallet-pc-pay-payway') && !available);
    if (!disabled) {
      this.payWay = payWay;
      if (!this.iousInfo && (payWay.code === 'finance-pc-pay-payway' || payWay.code === 'finance-other-pc-pay-payway') && this.orderInfo?.amount) {
        this.queryPcCashierFinanceVo(this.orderInfo?.amount);
      }
    }
  });

  @action public toPay = () => {
    switch (this.payWay?.code) {
      case 'wechat-pc-pay-payway': {
        this.process = 1;
        this.getPayInfo();
        break;
      }
      case 'wallet-pc-pay-payway': {
        this.payFn();
        break;
      }
      case 'finance-pc-pay-payway':
      case 'finance-other-pc-pay-payway':
      {
        if (this.agreeIousProtal) {
          this.payFn();
        } else {
          message.error('需要同意协议');
        }
        break;
      }
      case 'digital-yuan-pc-pay-payway': {
        this.process = 1;
        this.getPayInfo();
        break;
      }
      case 'szbank-wechat-scan-payway': {
        this.process = 1;
        this.getPayInfo();
        break;
      }
      default: break;
    }
  };

  @action public payFn = async(): Promise<void> => {
    if (this.payWay?.thirdPartyFundsId === 2) {
      await this.pwdInputCallback(undefined);
    } else {
      const res: BaseData<boolean> = await request({ url: api.checkPasswordExist });
      if (!res.data) { // 支付密码不存在
        this.passwordModel.onOpenModal(false);
        return;
      }
      if (!this.payWay.charge || !this.payWay.chargeFee) {
        this.passwordModel && this.passwordModel.onOpenModal(true);
      } else {
        this.showConfirmPayModal();
      }
    }
  };

  @action public showConfirmPayModal = (): void => {
    this.confirmPayModel.onOpenModal();
  };

  // 获取微信、数字人民币支付签名信息等
  @action public getPayInfo = async(): Promise<void> => {
    const data = {
      mergeName: this.orderInfo.mergeName,
      payCashierCode: this.cashierCode,
      payWayCode: this.payWay?.code,
      sysType: '',
      totalAmount: this.payWay?.totalAmount,
    };

    // 批量支付
    if (this.batch) {
      Object.assign(data, {
        batch: true,
        outBatchNo: this.outBatchNo,
        totalOrder: this.totalOrder,
        notifyUrl: this.notifyUrl,
      });
    } else {
      Object.assign(data, { tradeOrderIdList: this.tradeOrderIdList });
    }
    const res: BaseData<WePayResponse> = await request({
      method: 'POST',
      url: this.batch ? api.cashierPayAsync : api.cashierPay,
      data,
      timeout: 120000,
    });
    this.wxPayUrl = res.data.data?.code_url;
    this.payChannelOrderNo = res.data?.payChannelOrderNo;
    this.timer && clearInterval(this.timer);
    this.dateTime = new Date().getTime();
    this.timer = setInterval(() => {
      this.queryPayResult();
    }, 2000);
  };

  // 扫码支付后查询支付结果的轮询
  @action public queryPayResult = async(): Promise<void> => {
    try {
      if (!this.payChannelOrderNo) {
        return;
      }
      const res: BaseData<PayResult> = await request({
        method: 'POST',
        url: api.queryChannelOrderStatus,
        data: { payChannelOrderNo: this.payChannelOrderNo },
      });
      if (res.data?.payStatus === 3 && this.payWay?.thirdPartyFundsId === 2) {
        this.handlePaymentPasswordCancel(res?.data?.payStatusValue);
        return;
      }

      if (res.data?.payStatus === 2) {
        this.payResult = true;
        this.process = 2;
        clearInterval(this.timer);
        if (this.payWay?.thirdPartyFundsId === 2) {
          this.h5Url = undefined;
          this.paymentPasswordOpen = false;
          this.paymentPasswordLoading = false;
          this.payChannelOrderNo = '';
        }
        return;
      }
      const duration = (new Date().getTime() - this.dateTime) / 1000;
      if (duration > 120) { // 120s后不再轮询
        clearInterval(this.timer);
      }
    } catch (e) {
      console.error(e);
      this.paymentPasswordLoading = false;
    }
  };

  @action
  public handlePaymentPasswordOk = () => {
    // this.paymentPasswordLoading = true;
    this.dateTime = new Date().getTime();
    this.timer && clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.queryPayResult();
    }, 2000);
  };

  @action
  public handlePaymentPasswordCancel = (failReason?: string) => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.payCallback(false, failReason);
    this.h5Url = undefined;
    this.paymentPasswordOpen = false;
    this.paymentPasswordLoading = false;
    this.payChannelOrderNo = '';
  };

  // 支付密码校验成功后回调
  public pwdInputCallback = async(encodedPassword: string): Promise<void> => {
    try {
      this.loading = true;
      const data = {
        payCashierCode: this.cashierCode,
        password: encodedPassword,
        payWayCode: this.payWay?.code,
        totalAmount: this.payWay?.totalAmount,
        loanTerm: (this.payWay?.code === 'finance-pc-pay-payway' || this.payWay?.code === 'finance-other-pc-pay-payway') ? this.loanTermNum : undefined,
        productCode: 'YLbaitiao',
        thirdPartyFundsId: this.payWay?.thirdPartyFundsId,
        enterpriseId: this.payWay?.enterpriseId,
      };

      // 批量支付
      if (this.batch) {
        Object.assign(data, {
          batch: true,
          outBatchNo: this.outBatchNo,
          totalOrder: this.totalOrder,
          notifyUrl: this.notifyUrl,
        });
      } else {
        Object.assign(data, { tradeOrderIdList: this.tradeOrderIdList });
      }
      const req = await request<BaseData<{ data: { url: string; };payChannelOrderNo: string; }>>({
        url: this.batch ? api.cashierPayAsync : api.cashierPay,
        timeout: 120000,
        method: 'post',
        data,
      });
      if (this.payWay.thirdPartyFundsId === 2) {
        this.h5Url = req?.data?.data?.url;
        this.payChannelOrderNo = req.data?.payChannelOrderNo;
        this.paymentPasswordOpen = true;
        this.handlePaymentPasswordOk();
        return;
      }
    } catch (e) {
      this.loading = false;
      message.destroy();
      this.confirmPayModel.visible = false;
      this.payCallback(false, e.data.info || e.data.data);
      return;
    } finally {
      this.loading = false;
    }
    message.destroy();
    this.confirmPayModel.visible = false;
    this.payCallback(true);
  };

  // 支付回调
  public payCallback = (payResult: boolean, failReason?: string): void => {
    this.process = 2;
    this.payResult = payResult;
    this.failReason = payResult ? '' : failReason;
  };

  public onPayResultClick = (): void => {
    if (this.payResult) { // 支付成功
      if (top.egenie) {
        top.egenie.closeTab('cashier');
        top.egenie.closeTab('wholeSalePage');
      } else {
        window.close();
      }
    } else { // 支付失败重试
      this.process = 0;
    }
  };

  @action public checkProtal = (id?: number, title?: string): void => {
    this.protalStore.showModal(id || this.iousInfo.agreementId, title || '白条协议');
  };

  @action public changeCheck = (e: CheckboxChangeEvent) => {
    this.agreeIousProtal = e.target.checked;
  };
}

import { Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import failIcon from '../../../assets/images/failIcon.png';
import successIcon from '../../../assets/images/succesIcon.png';
import styles from '../index.less';
import type CashierStore from '../store';

interface Props {
  store: CashierStore;
}

const PayResult = observer((props: Props) => {
  const {
    payResult,
    failReason,
    payWay,
    onPayResultClick,
  } = props.store;

  return (
    <div className={styles.resultWrap}>
      <img
        alt="info"
        className={styles.successIcon}
        src={payResult ? successIcon : failIcon}
      />
      <div className={styles.title}>
        {payResult ? '支付成功' : '支付失败'}
      </div>
      {payResult ? (
        <div className={styles.amount}>
          &yen;
          {payWay?.totalAmount?.toFixed(2)}
        </div>
      ) : (
        <div className={styles.reason}>
          {failReason}
        </div>
      )}
      <Button
        onClick={onPayResultClick}
        type="primary"
      >
        {payResult ? '完成' : '重试'}
      </Button>
    </div>
  );
});

export default PayResult;

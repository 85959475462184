import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popover, Radio, Spin } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React from 'react';
import balance from '../../../assets/images/balance.png';
import disableBalance from '../../../assets/images/disableBalance.png';
import tipsIcon from '../../../assets/images/tipsIcon.png';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from '../index.less';
import type { PayWay } from '../interface';
import type CashierStore from '../store';

const YILIAN_IOUS_STATUS = {
  未开通: '#FB071F',
  审核中: '#1978ff',
  已冻结: '#999BA4',
};

interface Props {
  store: CashierStore;
}

const Cashier = observer((props: Props) => {
  const { loading, orderInfo, payWayList, payWay, platformRealnameApply, agreeIousProtal, onChangePayType, toPay, checkProtal, changeCheck, iousInfo, onChangeNumber, loanTermNum } = props.store;
  const AmountDisabled = payWay?.code === 'wallet-pc-pay-payway' && (platformRealnameApply !== 2 || payWay?.totalAmount > payWay?.availableBalance);
  const getRealnameApplyText = (): string => {
    if (platformRealnameApply === 0) {
      return '未实名，请先实名认证';
    }
    if (platformRealnameApply === 1) {
      return '实名申请中';
    }
    if (platformRealnameApply === 3) {
      return '实名失败，请重新认证';
    }
    return '';
  };
  console.log(payWay, 'payWay');
  const { rateRule, instalment, coupon } = iousInfo || {};

  const jumpToRepayment = (): void => {
    if (top?.egenie?.openTab) {
      top.egenie.openTab('/egenie-ts-payment/iousBill/index', 'baitiaozhangdan', '白条账单');
    } else {
      window.open('/api/iam/module/switch?module=100001&forwardUrl=/egenie-erp-home/index?jumpTo=repayment');
    }
  };

  const fliterRadio = (item: PayWay): ReactNode => {
    const { code, name, logo, availableBalance, chargeFee, charge, status, available, reason, overdue } = item;
    const disabled = availableBalance < orderInfo.amount || platformRealnameApply !== 2 || ((code === 'finance-pc-pay-payway' || code === 'finance-other-pc-pay-payway' || code === 'wallet-pc-pay-payway') && !available);
    let renderItem = null;
    switch (code) {
      case 'wallet-pc-pay-payway':
        renderItem = (
          <Radio
            disabled={disabled}
            value={code}
          >
            <img
              alt=""
              className={styles.payTypeIcon}
              src={disabled ? disableBalance : balance}
            />
            <span className={disabled ? styles.disablePayWayName : styles.payWayName}>
              {name}
            </span>
            <span className={disabled ? styles.disableFirstTips : styles.firstTips}>
              可用余额
              <span className={styles.amount}>
                {availableBalance.toFixed(2)}
              </span>
              元
            </span>
            {charge && (
              <span className={styles.poundage}>
                手续费
                {chargeFee.toFixed(2)}
                元
              </span>
            )}
            {platformRealnameApply !== 2 && (
              <span className={styles.errorTips}>
                {getRealnameApplyText()}
              </span>
            )}
            {availableBalance < orderInfo.amount && (
              <span className={styles.errorTips}>
                余额不足，请先充值
              </span>
            )}
          </Radio>
        );
        break;
      case 'szbank-wechat-scan-payway':
      case 'wechat-pc-pay-payway':
      case 'digital-yuan-pc-pay-payway':
        renderItem = (
          <Radio
            value={code}
          >
            <img
              alt=""
              className={styles.payTypeIcon}
              src={`${PUBLIC_IMAGE_URL}${logo}.png`}
            />
            <span className={styles.payWayName}>
              {name}
            </span>
            {charge && (
              <span className={styles.poundage}>
                手续费
                {chargeFee.toFixed(2)}
                元
              </span>
            )}
          </Radio>
        );
        break;
      case 'finance-pc-pay-payway':
      case 'finance-other-pc-pay-payway':
        renderItem = (
          <Radio
            disabled={disabled}
            value={code}
          >
            <img
              alt=""
              className={styles.payTypeIcon}
              src={(disabled || (!available && status !== '审核中')) ? `${PUBLIC_IMAGE_URL}disabledyilianIousLogo.png` : `${PUBLIC_IMAGE_URL}yilianIousLogo.png`}
            />
            <span className={disabled ? styles.disablePayWayName : styles.payWayName}>
              {name}
            </span>
            {!available && (
              <span
                className={styles.enabledStatus}
                style={{ background: YILIAN_IOUS_STATUS[status] }}
              >
                {status}
              </span>
            )}
            {(!available && (status === '未开通' || status === '审核中')) && (
              <span className={styles.iousAd}>
                打白条采购，易操作、额度高、利息低
              </span>
            )}
            {available && (
              <div className={styles.amountWrap}>
                <span className={disabled ? styles.disableFirstTips : styles.firstTips}>
                  可用额度
                  <span className={styles.amount}>
                    {availableBalance && availableBalance.toFixed(2)}
                  </span>
                  元
                </span>
                {coupon && coupon.length > 0 && (
                  <>
                    <div className={styles.verticalLine}/>
                    <div className={styles.interestFree}>
                      {coupon}
                    </div>
                  </>
                )}
              </div>
            )}

            {
              overdue ? (
                <>
                  <span
                    className={styles.errorTips}
                    style={{ marginLeft: '22px' }}
                  >
                    {reason}
                  </span>
                  <Button
                    className={styles.jumpToRepayment}
                    onClick={jumpToRepayment}
                    type="primary"
                  >
                    立即还款
                  </Button>
                </>
              ) : (
                <span className={styles.reason}>
                  {reason}
                </span>
              )
            }

            {charge && (
              <span className={styles.poundage}>
                手续费
                {chargeFee.toFixed(2)}
                元
              </span>
            )}
            {platformRealnameApply !== 2 && (
              <span className={styles.errorTips}>
                {getRealnameApplyText()}
              </span>
            )}

            {
              ((payWay?.code === 'finance-pc-pay-payway' || payWay?.code === 'finance-other-pc-pay-payway' && !disabled)) && (
                <>
                  <div className={styles.payWayContentWrap}>
                    {/** 占位,用于对齐分期内容 */}
                    <span
                      className={disabled ? styles.disablePayWayName : styles.payWayName}
                      style={{ color: '#00000000' }}
                    >
                      {name}
                    </span>
                    <div className={styles.billInfo}>
                      <div className={styles.ruleDes}>
                        <span>
                          费率规则
                        </span>
                        <Popover
                          content={rateRule}
                          placement="right"
                        >
                          <InfoCircleOutlined style={{
                            fontSize: 14,
                            color: '#999BA4',
                            marginLeft: 4,
                          }}
                          />
                        </Popover>
                      </div>
                      <div
                        className={styles.splitPayContentWrap}
                      >
                        {
                          instalment && instalment.map((item, index) => {
                            const { date, rate, loanTerm, number } = item;
                            return (
                              <div
                                className={styles.splitPayWrap}
                                key={loanTerm}
                                onClick={() => {
                                  onChangeNumber(loanTerm);
                                }}
                              >
                                <div
                                  className={styles.splitPay}
                                  style={{ border: loanTermNum === loanTerm ? '1px solid #1978ff' : '1px solid #e2e2e5' }}
                                >
                                  <span>
                                    {loanTerm === 1 ? number : `${loanTerm}个月`}
                                  </span>
                                  <span className={styles.serviceFee}>
                                    {rate}
                                  </span>
                                  {loanTermNum === loanTerm && (
                                    <img
                                      alt=""
                                      className={styles.hasCheckedImg}
                                      src={`${PUBLIC_IMAGE_URL}hasChecked.png`}
                                    />
                                  )}
                                </div>
                                <span
                                  className={styles.ruleDes}
                                  style={{ marginTop: 8 }}
                                >
                                  {/* 还款日2022年06月30日 */}
                                  {date}
                                </span>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <div>
                    <Checkbox
                      checked={agreeIousProtal}
                      onChange={changeCheck}
                    />
                    {(item.thirdPartyFundsId === 1 || item.thirdPartyFundsId === 4) ? (
                      <span
                        onClick={() => {
                          checkProtal();
                        }}
                        style={{ color: '#1978ff' }}
                      >
                        《白条协议》
                      </span>
                    ) : undefined}
                    {item.thirdPartyFundsId === 2 ? (
                      <>
                        <span
                          onClick={() => {
                            checkProtal(24, '保理业务同意书');
                          }}
                          style={{ color: '#1978ff' }}
                        >
                          《保理业务同意书》
                        </span>
                        <span
                          onClick={() => {
                            checkProtal(25, '贷款合同');
                          }}
                          style={{ color: '#1978ff' }}
                        >
                          《贷款合同》
                        </span>
                        <span
                          onClick={() => {
                            checkProtal(26, '个人征信授权书');
                          }}
                          style={{ color: '#1978ff' }}
                        >
                          《个人征信授权书》
                        </span>

                      </>
                    ) : undefined}
                  </div>
                </>
              )
            }
          </Radio>
        );
        break;
    }
    return renderItem;
  };

  return (
    <Spin
      size="large"
      spinning={loading}
    >
      <div className={styles.cashierContent}>
        <div className={styles.title}>
          <div className={styles.titleTxt}>
            <span className={styles.titleIcon}/>
            收银台
          </div>
          <div className={styles.header}>
            <div className={styles.label}>
              商品名称：
              {orderInfo.mergeName}
            </div>
            <div className={styles.label}>
              商品金额：
              <span className={styles.amount}>
                &yen;
                {orderInfo.amount.toFixed(2)}
              </span>
              元
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.yilianPay}>
            依链支付
          </div>
          <Radio.Group
            value={payWay?.code}
          >
            {
              payWayList.map((item) => (
                <div
                  className={`${styles.radioWrapper } ${(payWay?.code === item.code) ? (payWay?.code === 'finance-pc-pay-payway' || payWay?.code === 'finance-other-pc-pay-payway') ? styles.iousRadioWrapSelect : styles.radioWrapperSelect : '' }`}
                  key={item.payWayId}
                  onClick={() => onChangePayType(item)}
                >
                  {fliterRadio(item)}
                </div>
              ))
            }
          </Radio.Group>
        </div>
        <div className={styles.footer}>
          <div className={styles.left}>
            <div className={styles.wrapper}>
              <span className={styles.totalAmount}>
                &yen;
                {payWay?.totalAmount?.toFixed(2)}
              </span>
              <span className={styles.blackText}>
                元
              </span>
              {payWay?.charge && (
                <>
                  <span className={styles.grayText}>
                    (含手续费
                  </span>
                  <span className={styles.blackText}>
                    &yen;
                    {payWay?.chargeFee?.toFixed(2)}
                  </span>
                  <span className={styles.grayText}>
                    元)
                  </span>
                </>
              )}
            </div>
            {payWay?.charge && (
              <div className={styles.tips}>
                <img
                  className={styles.tipsIcon}
                  src={tipsIcon}
                />
                {payWay?.feeRemark}
              </div>
            )}
          </div>
          <Button
            className={styles.payBtn}
            disabled={AmountDisabled || !payWay}
            onClick={toPay}
            type="primary"
          >
            立即支付
          </Button>
        </div>
      </div>
    </Spin>

  );
});

export default Cashier;

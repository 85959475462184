import { Button, Col, Form, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import safe from '../../../../assets/images/safe.png';
import PasswordInput from '../passwordInput';
import styles from './index.less';
import type PasswordModel from './model';

@observer
export default class PasswordModal extends React.Component<{ store?: PasswordModel; }> {
  render() {
    const { store } = this.props;
    return (
      <Modal
        centered
        className={styles.setPasswordModal}
        footer={store.passwordFormType ? null : [
          <Button
            key={nanoid()}
            onClick={() => store.onOkModal()}
            type="primary"
          >
            确认
          </Button>,
        ]}
        maskClosable={false}
        onCancel={() => store.onCloseModal()}
        open={store.visible}
        title={store.passwordFormType ? '支付验证' : '设置支付密码'}
        width={440}
      >
        <Form
          labelCol={{ span: 7 }}
          ref={store.passwordFormRef}
          wrapperCol={!store.passwordFormType ? { span: 17 } : { span: 24 }}
        >
          {
            store.passwordFormType ? <VerifyPassword store={store}/> : <SetPassword store={store}/>
          }
        </Form>
      </Modal>
    );
  }
}

@observer
class SetPassword extends React.Component<{ store?: PasswordModel; }> {
  render() {
    const { store } = this.props;
    return (
      <>
        <img
          alt=""
          className={styles.safeIcon}
          src={safe}
        />
        <p className={styles.title}>
          为快捷使用与账户安全请设置6位数支付密码
        </p>
        <Row>
          <Col span={24}>
            <PasswordInput store={store.passwordInputModel}/>
          </Col>
        </Row>
      </>
    );
  }
}

@observer
class VerifyPassword extends React.Component<{ store?: PasswordModel; }> {
  render() {
    const { store } = this.props;
    return (
      <>
        <img
          alt=""
          className={styles.safeIcon}
          src={safe}
        />
        <p className={styles.title}>
          请输入6位数支付密码
        </p>
        <Row>
          <Col
            offset={3}
            span={18}
          >
            <PasswordInput store={store.passwordInputModel}/>
          </Col>
        </Row>
        <div className={styles.findPwdRow}>
          <span
            className={styles.findPwd}
            onClick={() => {
              window.top.egenie.openTab('/egenie-ts-payment/accountCenter', 2684, '支付账户中心');
            }}
          >
            找回密码
          </span>
        </div>
        <Button
          className={styles.payBtn}
          loading={store.loading}
          onClick={() => store.onOkModal()}
          type="primary"
        >
          立即支付
        </Button>
      </>
    );
  }
}
